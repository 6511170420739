/* Hide scrollbar for Chrome, Safari, and Opera */
html, body {
    /* height: 100vw; */
    margin: 0;
    /* overflow: hidden; */
  margin: 0;
  padding: 0;
  }

.no-scroll,
.no-scroll html,
.no-scroll body {
  overflow-y: hidden !important;
}