/* Wrapper styles */

/* Default table styling */
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}
.responsiveImage picture {
  width: 300px;
  float: left;
  margin-right: 15px;
}

/* Make table responsive below 424px */
@media (max-width: 768px) {
  .responsiveImage picture {
    width: 90vw;
    float: left;
    margin-right: 2px;
  }

  table {
    display: block; /* Allow it to break into blocks */
  }

  thead {
    display: none; /* Hide headers on small screens */
  }

  tr {
    display: flex;
    flex-direction: row; /* Align the row horizontally */
    flex-wrap: wrap;
    justify-content: flex-start; /* Optional: distribute cells evenly */
    margin-bottom: 10px; /* Add spacing between rows */
    border: 1px solid #ddd; /* Optional: Row borders for clarity */
  }

  td {
    padding: 2px 1px;
  }

  td::before {
    content: attr(
      data-label
    ); /* Use the data-label attribute to mimic headers */
    font-weight: bold;
    margin-right: 10px;
    flex: 1; /* Ensure labels don't shrink too much */
  }
}
/* toptap{border: 5px solid black;}     */
tiptap:first-child {
  margin-top: 0;
}

/* List styles */
ul,
ol {
  padding: 0 1rem;
  margin: 1.25rem 1rem 1.25rem 0.4rem;
}

ul li p,
ol li p {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

/* Heading styles */
/* h1, h2, h3, h4, h5, h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    word-wrap: break-word;
  }
  
  h1, h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }
  
  h1 {
    font-size: 1.4rem;
  }
  
  h2 {
    font-size: 1.2rem;
  }
  
  h3 {
    font-size: 1.1rem;
  }
  
  h4, h5, h6 {
    font-size: 1rem;
  } */

/* Code and preformatted text styles */
code {
  background-color: #e0e0ff;
  border-radius: 0.4rem;
  color: #000;
  font-size: 0.85rem;
  padding: 0.25em 0.3em;
}

/* pre {
    background: #000;
    border-radius: 0.5rem;
    color: #ffffff;
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;
  } */

pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

/* Blockquote */
blockquote {
  border-left: 3px solid #cccccc;
  margin: 1.5rem 0;
  padding-left: 1rem;
}

/* Horizontal rule */
hr {
  border: none;
  border-top: 1px solid #dddddd;
  margin: 2rem 0;
}

/* Table styles */
table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}

td,
th {
  border: 1px solid #cccccc;
  box-sizing: border-box;
  min-width: 1em;
  padding: 6px 8px;
  position: relative;
  vertical-align: top;
}

td > *,
th > * {
  margin-bottom: 0;
}

th {
  background-color: #f0f0f0;
  font-weight: bold;
  text-align: left;
}

.selectedCell::after {
  background: #e0e0e0;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

/* Resize handle for columns */
.column-resize-handle {
  background-color: #8b00ff;
  bottom: -2px;
  pointer-events: none;
  position: absolute;
  right: -2px;
  top: 0;
  width: 4px;
}

/* Table wrapper */
.tableWrapper {
  margin: 1.5rem 0;
  overflow-x: auto;
}

/* Resize cursor */
.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap:2px;
  margin:0;
  padding:3px;
  font-size:.8rem;
  /* border:1px solid black; */
  border-radius:3px;
  background-color:#DBADAD;
  /* color:#ffffff; */
}
.toolbar input, select, button {
  color:#000000;
  font-size:.8rem;
}
.toolbar button {
  text-transform: lowercase;
  padding: 1;
  border-radius:3px;
}
