/* Styling for tables inside the editor */
.ProseMirror table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .ProseMirror th,
  .ProseMirror td {
    border: 1px solid black;
    padding: 8px;
  }
  
  .ProseMirror th {
    background-color: #f0f0f0;
    text-align: left;
  }

  .picimage{
    width:300px;
  }

  /* .responsiveImage{
    width:300px;
    float:left;
    margin-right:15px;
  } */




  * {
    box-sizing: border-box;
  }
  
  html,
  body {
    padding: 0;
    margin: 0;
  }
  
  body {
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }
  
 