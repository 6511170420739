@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body,
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  /* max-width: 800; */
  font-family: roboto;
  width: 100%;
}
div {
  font-family: roboto;
}
.App {
  text-align: center;
  font-family: "roboto";
}

.site-title {
  /* font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family: proxima-nova; */
  font-family: Roboto;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1em;
  width: 100px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media print {
  .noprint {
    display: none;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sqs-block .embed-block .sqs-block-embed {
  padding: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
