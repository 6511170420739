@media print
{
.noprint {display:none;}
}

.csvbutton {
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
  label {
    font-size:1em;
    text-transform: lowercase;
    font-weight: normal;
    margin:10px;
  }

  .textField{
    border:1px solid black;
    border-radius:3px;
    background-color: #f0f0f0;
    height:25px
  }